import React from "react";

const Contact = () => {
  return (
    <div id="slide-five" class="five">
      <div class="light"></div>
      <div class="contact front">
        <img src="logow.png" alt="Logo"></img>
      </div>
      <div class="contact back">
        <div class="left">
          <div class="name-text">Pacioli Contadores</div>
          <div class="role-text">Asesoría Fiscal</div>
        </div>
        <div class="right">
          <div>
            <strong>Teléfono:</strong>
          </div>
          <div>
            <a
              class="contact-link"
              href="https://api.whatsapp.com/send?phone=525535877704"
              target="_blank"
              rel="noreferrer"
            >
              <h5>+52 55 3587 7704</h5>
            </a>
          </div>
          <div>Email:</div>
          <div>
            <a
              class="contact-link"
              href="mailto: clientes@paciolicontadores.info"
              target="_blank"
              rel="noreferrer"
            >
              <h5>clientes@paciolicontadores.info</h5>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
