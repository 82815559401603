import React from "react";

const Service = () => {
  return (
    <div className="aviso">
      <div>
        <center>
          <h2>Aviso de Privacidad</h2>
        </center>
      </div>

      <div>
        <p>
          <h5>
            Pacioli Contadores, es responsable del uso, tratamiento y protección
            de los datos personales que le sean solicitados para la prestación
            de servicios legales y representación ante autoridades, por lo que
            en cumplimiento a lo dispuesto por la Ley Federal de Protección de
            Datos Personales en Posesión de los Particulares (la “Ley”), emite
            el presente Aviso de Privacidad.
          </h5>
        </p>
      </div>
      <div>
        <p>
          <h5>
            Los datos personales recabados por Pacioli Contadores serán tratados
            observando puntualmente los principios de licitud, consentimiento,
            información, calidad, finalidad, lealtad, proporcionalidad y
            responsabilidad previstos en Ley y serán utilizados única y
            exclusivamente para proveer los servicios de litigio y consultoría
            en materia de derecho fiscal, derecho administrativo y comercio
            exterior, así como los servicios contables en los que se especializa
            la Firma.
          </h5>
        </p>
      </div>
      <div>
        <p>
          <h5>
            Los datos personales que le solicitemos para la prestación de
            nuestros servicios serán utilizados con las siguientes finalidades:
          </h5>
        </p>
      </div>
      <div>
        <h5>
          Establecer contacto por vía telefónica o correo electrónico con usted
          y dar seguimiento a los asuntos encomendados. Tramitar los asuntos
          encomendados antes las autoridades correspondientes. Generar un
          registro para la base de datos interna. Fines estadísticos. Compartir
          noticias de interés jurídico-empresarial. Realizar todas las gestiones
          internas necesarias relacionadas con la prestación contractual que se
          encuentre vigente, incluyendo cuestiones de pagos de servicios y
          emisión de recibos. Representación ante autoridades administrativas y
          judiciales.
        </h5>
      </div>
      <div>
        <p>
          <h5>
            En este sentido, para lograr cumplir con las finalidades antes
            señaladas, resulta necesario obtener los siguientes datos
            personales: Nombre, edad, género, nacionalidad, dirección, lugar y
            fecha de nacimiento, estado civil, número de hijos, dirección de
            correo electrónico y teléfono (ya sea fijo y/o móvil), clave del
            Registro Federal de contribuyentes, identificación oficial con
            fotografía, credencial de elector, licencia de conducir, pasaporte,
            comprobante de domicilio, recibo de teléfono, declaraciones de
            impuestos mensuales, anuales, informativas y complementarias,
            declaraciones patrimoniales, estados de cuenta bancarios, estados de
            cuenta de inversiones, escrituras públicas de propiedades,
            información corporativa (titularidad de acciones de compañías),
            régimen bajo el cual tributa, contraseña de su portal en la
            plataforma del Servicio de Administración Tributaria, firma
            electrónica avanzada y su firma autógrafa.
          </h5>
        </p>
      </div>
      <div>
        <p>
          <h5>
            Adicionalmente, le informamos que derivado de la naturaleza de los
            servicios que ofrecemos al público en general, no será necesario que
            nos proporcione ningún tipo de dato personal sensible que pudiese
            necesitar un especial cuidado o protección específica.
          </h5>
        </p>
      </div>
      <div>
        <p>
          <h5>
            Los datos personales serán recabados directamente del titular de los
            mismos, ya sea dentro de las oficinas de Pacioli Contadores, o a
            través de medios telefónicos y/o electrónicos disponibles.
          </h5>
        </p>
      </div>
      <div>
        <p>
          <h5>
            Los datos personales que nos proporcione se resguardarán bajo
            estrictas medidas de seguridad administrativas, técnicas y físicas,
            procurando con ello resguardar la integridad de dichos datos y
            evitar su daño, pérdida, alteración, destrucción o uso no
            autorizado.
          </h5>
        </p>
      </div>
      <div>
        <p>
          <h5>
            Nuestra base de datos personales recabados, será manejada únicamente
            por personal autorizado para ello, el cual observa y cumple los
            principios de confidencialidad y protección contenidos en la Ley.
          </h5>
        </p>
      </div>
      <div>
        <p>
          <h5>
            Usted podrá ejercitar los derechos de Acceso, Rectificación,
            Cancelación u Oposición (Derechos ARCO) sobre sus datos personales,
            así como la revocación en cualquier momento del consentimiento que
            otorgó para su tratamiento o solicitar que se limite su uso o
            divulgación, a través de los procedimientos que hemos implementado
            para ello. Para conocer y llevar a cabo dichos procedimientos, sus
            requisitos y plazos póngase en contacto a través del correo
            electrónico privacidad@paciolicontadores.info, o vía telefónica al
            (55) 3587 7704.
          </h5>
        </p>
      </div>
      <div>
        <p>
          <h5>
            Adicionalmente, le informamos que para ejercer los derechos ARCO a
            través del procedimiento antes mencionado, será necesario entregar
            por escrito una solicitud, la cual deberá de contener:
          </h5>
        </p>
      </div>
      <div>
        <p>
          <h5>
            Nombre del titular y domicilio u otro medio para comunicarle la
            respuesta a su solicitud; Documentos que acrediten la identidad o,
            en su caso, la representación legal del titular; Descripción clara y
            precisa de los datos personales respecto de los que se busca ejercer
            alguno de los derechos establecidos en la Ley Federal de Protección
            de Datos Personales en Posesión de los Particulares; y; Cualquier
            otro elemento o documento que facilite la localización de los datos
            personales.
          </h5>
        </p>
      </div>
      <div>
        <p>
          <h5>
            Asimismo, le informamos que Pacioli Contadores, NO transferirá los
            datos personales a terceros ajenos a esta empresa, y únicamente
            transmitirá a las autoridades pertinentes la información
            estrictamente necesaria para el cumplimiento de los fines señalados
            anteriormente.
          </h5>
        </p>
      </div>
      <div>
        <p>
          <h5>
            En caso de existir algún cambio o modificación total o parcial al
            presente Aviso de Privacidad, para cumplir nuevas legislaciones o
            reformas a las ya existentes, cambios de nuestras políticas internas
            o requerimientos para la prestación de nuestros servicios, Pacioli
            Contadores pondrá a su disposición los mismos en la página de
            internet: www.paciolicontadores.versel.com/politicas
          </h5>
        </p>
      </div>
      <div>
        <p>
          <h5>Pacioli Contadores</h5>
        </p>
      </div>
      <div>
        <p>
          <h5>Última actualización 30 de enero de 2022.</h5>
        </p>
      </div>
    </div>
  );
};

export default Service;
