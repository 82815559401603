import React from "react";

const Home = () => {
  return (
    <>
      <div className="aviso">
        <div className="mhead">
          <h1>Soluciones en</h1>
          <h1>Contabilidad y Asesoría Fiscal</h1>
        </div>
        <div className="mtext">
          <h3>
            Pacioli Contadores es un despacho contable comprometido con sus
            clientes, brindandole tranquilidad a su organización mediante el uso
            de buenas practicas en el cumplimiento de las disposciones fiscales
            vigentes en el país
          </h3>
        </div>

        <img src="02.jpg" alt="imagen" className="home"></img>
        <div className="mfooter">
          <h5>
            <strong>Contacto</strong>
          </h5>
          <h5>Teléfono: 55 3587 7704</h5>
          <h5>Correo: clientes@paciolicontadores.info</h5>
        </div>
      </div>
    </>
  );
};

export default Home;
